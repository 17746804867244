<template>
  <div></div>
</template>

<script>
export default {
  name: 'AsyncAwaitDemo',
  data() {
    return {}
  },

  created() {
    console.log(this.testAsync())
    this.outputResource()
  },

  methods: {
    stepA() {
      return new Promise(resolve => {
        setTimeout(() => {
          return resolve('A step success!')
        }, 1000)
      }, reject => {})
    },
    stepB(params) {
      return new Promise(resolve => {
        setTimeout(() => {
          return resolve(params + ',\nB step success!')
        }, 1000)
      }, reject => {})
    },
    async outputResource() {
      const successA = await this.stepA()
      const successB = await this.stepB(successA)
      console.log(successA)
      console.log(successB)
    },

    async testAsync() {
      return 'async test!!!'
    }
  }
}
</script>
